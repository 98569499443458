<template>
  <v-app id="app">
    <HeaderNav v-if="getLogin && !getisAPP" :verMenu="!getbMenuBlock"/>
    <v-main>
      <div class="cont-version">V {{versionPWA}}</div>
      <div class="pa-0 ma-0 fill-viewport-height">
       <transition name="custom-classes-transition"
        enter-active-class="animated fadeInLeft"
        >
      <router-view/>
      </transition>
    </div>
    </v-main>
    <Notificacion/>
    <ModalCargando />
    <FirebaseNotificacion/>
    <NavBarFooter v-if="getLogin && !getisAPP && !getbMenuBlock"/>
  </v-app>
</template>
<script>
import Notificacion from '@/components/Notificacion.vue'
import FirebaseNotificacion from '@/components/FirebaseNotificacion.vue'
import NavBarFooter from '@/components/NavBarFooter.vue'
import HeaderNav from '@/components/HeaderNav.vue'
import ModalCargando from "@/components/ModalCargando.vue";
import axiosServices from '@/services/axiosServices.js'
import { mapGetters } from 'vuex'
import inifirebase from './firebase/inifirebase'; 
import { version } from "../package.json"
export default {
  name: "App",
  components: {
    Notificacion,
    FirebaseNotificacion,
    NavBarFooter,
    HeaderNav,
    ModalCargando
  },
  data() {
    return {
      nContadorLog:0,
      versionPWA: version
    }
  },
  mounted() {
    // inifirebase.onEjecutarFirebase(this.$firebase)
  },
  computed: {
    ...mapGetters(['getisOnline', 'getLogin', 'getisAPP']),
    ...mapGetters('axiosOffline',['getaAxiosOffline']),
    ...mapGetters(['getbMenuBlock'])
  },
  watch: {
    getisOnline(newValue) {
      // this.$vuetify.theme.dark = !this.getisOnline
      // valido que si es true y hay offline se llama la funcion de acions para eso
      if(newValue  && (this.getaAxiosOffline.length > 0) ){
        axiosServices.enviarAllOffline()
      }
    },
    getLogin(){
      if(this.getLogin && this.nContadorLog === 0 ){
        this.nContadorLog++
        inifirebase.onEjecutarFirebase(this.$firebase)
      }else{
        this.nContadorLog = 0
        inifirebase.onDetenerFirebase(this.$firebase)
      }
    }

  },
};
</script>
<style lang="scss">
@import  'css/fonts.scss';
@import  'css/text.scss';
@import  'css/global.scss';
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-size: 16px;
  font-family: 'Poppins';
  line-height: 18px;
  @media screen and (max-width: 600px){
    font-size: 14px;
  }
}
.fill-viewport-height {
  height: 100%; //Antes estaba 100% pero no funcionaba en navegadores iOS
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.cont-version{
  position: absolute;
  bottom: 0px;
  right: 2px;
  font-size: 7px;
  color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
</style>
